// src/config.js
const config = {
    // BASE_URL: "http://localhost:5000",  // Use the actual base URL of your API
    //FRONTEND_BASE_URL: "http://localhost:3000",  // Use the actual base URL of your API
    // BASE_URL: "http://92.205.167.231:5000",
    // FRONTEND_BASE_URL: "http://92.205.167.231",  // Use the actual base URL of your API
    BASE_URL: "https://api.iveydb.com",
    FRONTEND_BASE_URL: "https://iveydb.com",  // Use the actual base URL of your API
    AUDIENCE_ID: "85f1175b82",
    MAILCHIMP_API_KEY: "d020b4d75fb013cbcec8ae2766dfa5f3-us4",
    DATACENTER: "us4"
};
  
export default config;
  